import React from "react";
import "./TitleStepper.css";

function TitleHeading({ text }) {
  return (
    <div className="font-gt title-stepper-container lg:text-6xl text-3xl">
      {text}
    </div>
  );
}

export default TitleHeading;
