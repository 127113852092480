import React from "react";
import "./PrimaryButton.css";

function PrimaryButton({ text, url, className, icon, ...attrs}) {
  return (
    <a
      className={`primary-button mb-16 sm:mb-0 ${className}`}
      href={url}
      {...attrs}
    >
      <div className="button-border-overlay border-primary border-4 "></div>
      {text}
    </a>
  );
}

export default PrimaryButton;
