import React from "react";
import { StaticImage } from "gatsby-plugin-image"

import Title from "../components/Title/Title";
import { jerseyNumbers } from "../constants/app.constants";
// import useVisibility from "../hooks/useVisibilty";
function CollectibleTiers() {
  // const [beforeCheckoutSubmitShown, beforeCheckoutSubmitRef] = useVisibility();

  return (
    <section className="bg-lightGray text-center w-full bg-image-waves relative ">
      <div className="h-min-screen md:px-40 sm:w-full">
        <Title text="COLLECTIBLE TIERS" />
        <p className="text-lg">
          Collect limited edition digital cards from drops & marketplace
        </p>
        <div className="flex lg:items-center lg:justify-center py-10 md:px-40 overflow-x-scroll lg:overflow-x-hidden gap-6">
          <div>
          <StaticImage src="../assests/images/cards/DW31.png" alt="Standard" placeholder="none" className="w-80" quality={90}/>

            {/* <img
              src={tierStandard}
              alt="Card1"
              className="w-76 max-w-none max-h-none"
            /> */}
            <div className="mt-2">
              <div className="sm:text-3xl font-bold font-gt">Standard</div>
              <div className="sm:text-base text-sm font-thin">
                70+ Cards Per Season
              </div>
            </div>
          </div>
          <div>
          <StaticImage src="../assests/images/cards/R_RS45.png" alt="Rare" placeholder="none" className="w-80 max-w-none" quality={90}/>
            {/* <img src={tierRare} alt="Card2" className="w-80 max-w-none p-4" /> */}
            <div className="mt-2">
              <div className="sm:text-3xl font-bold font-gt">Rare</div>
              <div className="sm:text-base text-sm font-thin">
                25 Cards Per Season
              </div>
            </div>
          </div>
          <div>
          <StaticImage src="../assests/images/cards/E_VK18.png" alt="Epic" placeholder="none" className="w-80" quality={90}/>

            {/* <img src={tierEpic} alt="Card2" className="w-80 max-w-none" /> */}
            <div className="mt-2">
              <div className="sm:text-3xl font-bold font-gt">Epic</div>
              <div className="sm:text-base text-sm font-thin">
                5 Cards Per Season
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-32">
        <Title text="COMPETE" />
        <p className="text-lg ">
          Compete for live matches globally & win exclusive rewards
        </p>
        <div className="flex justify-center w-full text-center sm:py-16 py-10 px-20 gap-6">
          <div className="floating">
          <StaticImage 
            src="../assests/images/cards/DW31.png" 
            alt="Card 1" 
            placeholder="none" 
            className="inline-flex sm:mt-36 mt-18 sm:block w-64 max-w-none opacity-50 md:opacity-100"
            quality={90}
          />

            {/* <img
              src={competeCard1}
              alt="Card1"
              className="inline-flex sm:mt-36 mt-18 sm:block w-64 max-w-none opacity-50 md:opacity-100 px-4"
            /> */}
          </div>
          <div className="floating-alt">
          <StaticImage 
            src="../assests/images/cards/PC30.png" 
            alt="Card 2" 
            placeholder="none" 
            className="inline-flex sm:mt-16 mt-9 sm:block w-64 max-w-none opacity-50 md:opacity-100"
            quality={90}
          />
            {/* <img
              src={competeCard2}
              alt="Card1"
              className="inline-flex sm:mt-16 mt-9 sm:block w-64 max-w-none opacity-50 md:opacity-100 px-4"
            /> */}
          </div>
          <div className="floating">
          <StaticImage 
            src="../assests/images/cards/E_VK18.png" 
            alt="Card 2" 
            placeholder="none" 
            className="inline-flex w-64 max-w-none"
            quality={90}
          />
            {/* <img
              src={competeCard3}
              alt="Card2"
              className="inline-flex w-64 max-w-none px-4"
            /> */}
          </div>
          <div className="floating-alt">
          <StaticImage 
            src="../assests/images/cards/RS45.png" 
            alt="Card 2" 
            placeholder="none" 
            className="inline-flex sm:mt-16 mt-9 sm:block w-64 max-w-none opacity-50 md:opacity-100"
            quality={90}
          />
            {/* <img
              src={competeCard4}
              alt="Card3"
              className="inline-flex sm:mt-16 mt-9 sm:block w-64 max-w-none opacity-50 md:opacity-100 px-4"
            /> */}
          </div>
          <div className="floating">
          <StaticImage 
            src="../assests/images/cards/MSD7.png" 
            alt="Card 2" 
            placeholder="none" 
            className="inline-flex sm:mt-36 mt-18 sm:block w-64 max-w-none opacity-50 md:opacity-100"
            quality={90}
          />
            {/* <img
              src={competeCard5}
              alt="Card3"
              className="inline-flex sm:mt-36 mt-18 sm:block w-64 max-w-none opacity-50 md:opacity-100 px-4"
            /> */}
          </div>
        </div>
      </div>
      <div
        className="absolute font-gt md:ml-10 md:text-4xl md:visible invisible opacity-20"
        style={{ top: "-8rem", writingMode: "vertical-lr" }}
      >
        {jerseyNumbers.map((item, index) => (
          <span key={index}>{item}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        ))}
      </div>
    </section>
  );
}

export default CollectibleTiers;
