import React from "react";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import TitleHeading from "../components/TitleStepper/TitleStepper";
import { discordURL } from "../constants/app.constants";
import { StaticImage } from "gatsby-plugin-image";

function Hero() {
  return (
    <>
      <section className="grid grid-cols-1 sm:grid-cols-2 w-full bg-darkGray relative bg-cards sm:overflow-y-hidden sm:h-screen">
        <div className="absolute bg-linear-overlay h-full w-full z-30 opacity-90 block sm:hidden"></div>
        <div className="sm:py-36 sm:px-40 pt-16 px-20 relative z-40">
          <TitleHeading text="LIMITED EDITION COLLECTIBLES" />
          <br />
          <p>
            Collect limited edition cricket collectibles(NFTs), trade on
            marketplace & compete in live matches to earn exclusive rewards
          </p>
          <br />
          <div className="flex w-100">
            <PrimaryButton
              text="JOIN COMMUNITY&nbsp;&nbsp;&#8594;"
              url={discordURL}
              className="mr-5"
              target="_blank"
            />
            {/* <PrimaryButton text="TWEET" url={appURL} /> */}
          </div>
        </div>
        <div className="overflow-hidden z-10 hidden sm:block">
          <StaticImage
            src="../assests/images/cards/Cards-BG-min.png"
            alt="cards"
            className="hero-bg-image"
            placeholder="none"
            quality={100}
          />
        </div>
        <div className="absolute z-10 opacity-20 bottom-32">
          <StaticImage
            src="../assests/images/landing-tagline.svg"
            alt="COLLECT PLAY TRADE"
            className="max-w-full"
          />
          {/*<img src={tagline} alt="COLLECT PLAY TRADE" className="max-w-full" />*/}
        </div>
      </section>
    </>
  );
}

export default Hero;
