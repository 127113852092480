import Title from "../components/Title/Title";
import React from "react";
import Discord from "../assests/images/discord-logo.svg";
import Twitter from "../assests/images/twitter-logo.png";
import { twitterURL, discordURL } from "../constants/app.constants";

function JoinCommunity() {
  return (
    <section className="grid sm:grid-cols-3 grid-cols-1 bg-primary text-center w-full justify-center items-center">
      <div className="flex flex-col py-20 col-span-2 items-center">
        <Title text="Join Community" />
        <p className="text-lg">Feel the heat & Explore where you stand</p>
      </div>
      <div className="flex flex-row items-center justify-center bg-lightGray sm:h-full py-2">
          <a href={discordURL}>
              <img
              className="h-20 m-2 cursor-pointer"
              src={Discord}
              width={64}
              alt="Discord"
            />
          </a>
          <a href={twitterURL}>
              <img
                  className="h-16 m-2 cursor-pointer"
                  src={Twitter}
                  width={64}
                  alt="Twitter"
              />
          </a>

      </div>
    </section>
  );
}

export default JoinCommunity;
