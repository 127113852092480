import * as React from "react";
import LandingPage from "../sections/LandingPage";
import {Helmet} from "react-helmet"

// markup
const IndexPage = () => {
    return <>
        <Helmet>
            <title>CricTez</title>
            <meta name="title" content="CricTez"/>
            <meta name="description" content="Collect limited edition cricket collectibles(NFTs), trade on marketplace & compete in live matches to earn exclusive rewards"/>

            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://crictez.io/"/>
            <meta property="og:title" content="CricTez"/>
            <meta property="og:description" content="Collect limited edition cricket collectibles(NFTs), trade on marketplace & compete in live matches to earn exclusive rewards"/>
            <meta property="og:image" content="https://crictez.io/static/preview_banner-28bcf520d1851fc885c974d66b714900.png"/>

            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content="https://crictez.io/"/>
            <meta property="twitter:title" content="CricTez"/>
            <meta property="twitter:description" content="Collect limited edition cricket collectibles(NFTs), trade on marketplace & compete in live matches to earn exclusive rewards"/>
            <meta property="twitter:image" content="https://crictez.io/static/preview_banner-28bcf520d1851fc885c974d66b714900.png"/>
        </Helmet>
        <LandingPage/>
    </>;
};

export default IndexPage;
