import React from "react";
import Title from "../components/Title/Title";
import card from "../assests/images/cards/E_RS_45.png";
import Tilt from "react-parallax-tilt";
import { StaticImage } from "gatsby-plugin-image"
const IPFSDemoURL =
  "https://ipfs.io/ipfs/QmaNzSaimVHA7H6exp9vqMwN2LZXzfTN37JqXDBXh3hXxJ";

function TrueNFT() {
  return (
    <section className="bg-lightGray text-center w-full py-36">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
        <div className="flex flex-col items-center justify-center">
          <Title text={"TRUE OWNERSHIP"} />
          <p>Stored on IPFS and Secured by Tezos Blockchain</p>
          <div className="flex items-center justify-center">
            <div className="logo">
              <StaticImage src="../assests/images/tezos-logo.jpg" alt="Tezos"  placeholder="none"/>
            </div>
            <div className="logo">
              <StaticImage src="../assests/images/ipfs-logo.png" alt="Tezos"  placeholder="none"/>
            </div>
          </div>
          <br />
          <button
            className="bg-primary inline-flex items-center border-0 py-1 px-3 focus:outline-none hover:bg-primaryDark text-white my-4 primary-button"
            onClick={() => window.open(IPFSDemoURL, "_blank")}
          >
            View Card on IPFS
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-4 h-4 ml-1"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"/>
            </svg>
          </button>
        </div>

        <div className="flex items-center justify-center">
          <Tilt
            transitionSpeed={2500}
            glareEnable={true}
            glareMaxOpacity={0.5}
            glareColor={"#C0C0C0"}
            glarePosition="all"
            className={"top-card"}
            gyroscope={true}
            glareBorderRadius="10px"
            tiltMaxAngleX={15}
            tiltMaxAngleY={15}

          >
            <img
              src={card}
              alt="Card1"
              className="w-64 max-w-none max-h-none	"
            />
          </Tilt>
        </div>
      </div>
    </section>
  );
}

export default TrueNFT;
