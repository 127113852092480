export const appName = "CricTez";
export const appURL = "https://discord.gg/efS9UTerFK";
export const jerseyNumbers = ["10", "333", "7", "19", "23", "18", "800", "45"];

export const faqItems = [
  {
    id: 0,
    heading: "What is CricTez?",
    content: `CricTez is a non-fungible token-based fantasy sports dapp, primarily focused on cricket. The core idea of dapp revolves around the representation of players/athletes as Non-Fungible Tokens(NFTs).
      These NFTs are wrapped as Cards or Collectibles, each representing a player with its unique card score. Each card’s score is realistically calculated based upon an algorithm that is dependent on the performance of players in real matches.`,
  },
  {
    id: 1,
    heading: "What is an Non-Fungible Token?",
    content: `A non-fungible token is a unit of data on a digital ledger called a blockchain, where each NFT can represent a unique digital item, and thus they are not interchangeable. NFTs can represent digital files such as art, audio, video, and other forms of creative work.`,
  },
  {
    id: 2,
    heading: "How to collect Cards?",
    content: `Crictez timely drops limited edition collectibles through auctions on the drops page. CricTez also has an in-built marketplace, where users can trade these collectibles and create their unique teams. The value/price of each card is heavily dependent on two main factors: The player it represents and its card score, which ultimately relies on the real performance of the player. Users can view detailed statistics of each card along with the cards in possession on the marketplace.`,
  },
  {
    id: 3,
    heading: "How to Play?",
    content: `Build your Team of 5 players and Compete for live matches with your collectibles to win rewards. `,
  },
  {
    id: 4,
    heading: "Where are the contracts?",
    content: `Neither the smart contract code, nor the frontend code was audited by a third party. We did review the contracts interally and tested it extensively over the last couple of weeks.`,
  },
];

export const twitterURL = "https://twitter.com/crictez_io";
export const discordURL = "https://discord.gg/dHaBeKjEDj";
