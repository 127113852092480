import React from "react";
import AccordianList from "../components/Accordian/Accordian";
import Title from "../components/Title/Title";
import { faqItems } from "../constants/app.constants";
function FAQs() {
  return (
    <section className="container lg:px-80 text-center bg-darkGray py-20">
      <Title text="FAQS" />
      <div className="sm:mt-20">
        <AccordianList data={faqItems} />
      </div>
    </section>
  );
}

export default FAQs;
