import React from "react";
import Navbar from "../components/Navbar/Navbar";
import CollectibleTiers from "./CollectibleTiers.section";
import FAQs from "./FAQs.section";
import Footer from "./Footer.section";
import GameFlow from "./GameFlow.section";
import Hero from "./Hero.section";
import JoinCommunity from "./JoinCommunity.section";
import TrueNFT from "./TrueNFT.section";

function LandingPage() {
  return (
    <div>
      <Navbar />
      <div className="text-white mx-auto flex flex-col items-center justify-center overflow-x-hidden landing-container">
        <Hero />
        <GameFlow />
        <CollectibleTiers />
        <TrueNFT />
        <FAQs />
        <JoinCommunity />
      </div>
      <Footer />
      <a
        href="https://crictez.medium.com/introducing-crictez-21a284c60e94?source=friends_link&sk=02043393826c723f41cb8bd8afadfa7b"
        target="_blank"
      >
        <div className="w-full sm:px-40 px-0 py-2 text-white text-center opacity-90 text-gray-600 body-font z-50 bg-primary sticky bottom-0 z-60">
          <div>
              Past venture, now in hibernation mode for potential future endeavours
          </div>
        </div>
      </a>
    </div>
  );
}

export default LandingPage;
