import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import Plus from "../../icons/plus.icon";
import Minus from "../../icons/minus.icon";

const AccordionList = ({ data }) => {
  return (
    <Accordion allowZeroExpanded>
      {data.map((item) => (
        <AccordionItem
          key={item.id}
          className="bg-lightGray border-primary border-0.5 my-2 px-4 cursor-pointer w-full"
        >
          <AccordionItemHeading>
            <AccordionItemButton className="text-white p-5 outline-none text-left sm:text-xl">
              <div>
                <span>{item.heading}</span>
                <AccordionItemState>
                  {({ expanded }) =>
                    expanded ? (
                      <span className="float-right">
                        <Minus />
                      </span>
                    ) : (
                      <span className="float-right">
                        <Plus />
                      </span>
                    )
                  }
                </AccordionItemState>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="text-white text-left p-4 pt-0 font-light">{item.content}</p>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default AccordionList;
