import React from "react";
import { StaticImage } from "gatsby-plugin-image"

function GameFlow() {
  return (
    <section className="w-full bg-lightGray sm:py-40 py-20 flex items-center justify-center">
      <div className="grid md:grid-cols-3 grid-cols-1 sm:gap-20 gap-10">
        <div>
          <StaticImage src="../assests/images/Collect.svg" alt="Collect" placeholder="none"/>
        </div>
        <div>
          <StaticImage src="../assests/images/Trade-1.png" alt="Trade" placeholder="none"/>
        </div>
        <div>
          <StaticImage src="../assests/images/Play.png" alt="Play" placeholder="none"/>
        </div>
      </div>
    </section>
  );
}

export default GameFlow;
